import React, { useState, useEffect } from "react"
import * as S from "@/components/Shop"
import styled from "styled-components"
import useBigScreen from "@/hooks/bigScreen"
import TabTableWrapper from "@/components/compatibility-list/TabTableWrapper"
import { getAdaptByCountry } from '@/services/request'

const TabWrapper = styled.div`
  max-width: 1000px;
  margin: 0px auto;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
`
const hlColor = `#0061FF`

const Tab = styled.div`
  display: block;
  position: relative;
  padding: 0 15px;
  font-size: 16px;
  margin: 15px 0px 0;
  line-height: 33px;
  height: 40px;
  color: ${props => (props.hl ? hlColor : "#D0D3D7")};
  font-weight: bold;
  transition: all 0.2s;
  flex: auto;
  cursor: pointer;
  text-align: center;
  &:hover {
    color: ${hlColor};
  }
  &:after {
    transition: all 0.2s;
    content: "";
    position: absolute;
    bottom: ${props => (props.hl ? `1px` : "0px")};
    left: 0;
    right: 0;
    height: ${props => (props.hl ? `3px` : "3px")};
    background-color: ${props => (props.hl ? hlColor : "#D0D3D7")};
    pointer-events: none;
  }
  @media screen and (max-width: 800px) {
    & {
      flex: none;
      width: 100%;
    }
  }
`
const CompatibilityTable = ({ langJson }) => {
  const [currentCate, setCate] = useState(4)
  const [tabs, setTabs] = useState([])
  useEffect(() => {
    if (!!langJson) {
      setTabs([
        langJson[`Support.Japan`],
        langJson[`Support.SouthKorea`],
        langJson[`NebulaPpTos.Spain`],
        langJson[`Support.Germany`],
        langJson[`Product.CD.CompatibleDevices_US`],
        langJson[`Support.GB`]
      ])
    }
  }, [langJson])

  const countryCode = ["JP", "KR", "ES", "DE", "US", "GB"
    //  "GB", "FR", "CN", "OTHER"
  ]

  const bigScreen = useBigScreen(656)
  // table data
  const [tbdata, setTbdata] = useState([])
  const getTbData = p => {
    getAdaptByCountry({ countryCode: p }).then(
      (d) => {
        // @ts-ignore
        if (d.data.code === 200) {
          // @ts-ignore
          if (d.data.data !== null && d.data.data.length > 0) {

            // @ts-ignore
            setTbdata(d.data.data)
          } else {
            setTbdata([])
          }
          // @ts-ignore
        } else throw d.data
      }
    )
  }

  useEffect(() => {
    getTbData(countryCode[currentCate])
  }, [currentCate])
  const renderFnDevi = text => {
    let arr = []
    for (var i = 0; i < text.length; i++) {
      // arr.push("Nreal " + text[i])
      arr.push(text[i])
    }
    return arr.length > 0 ? arr.join(", ") : arr
  }
  const renderFn = text => {
    return text ? (
      <svg
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 7.61702L6.74194 13L16 2"
          stroke="#62E760"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ) : (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.09949 12L11.9999 2"
          stroke="#AFAFAF"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M11.9005 12L2.00008 2"
          stroke="#AFAFAF"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    )
  }
  const columns = [
    {
      title: !!langJson && langJson[`Nebula.phone.tablehd.title1`],
      dataIndex: "modelName",
      key: "modelName",
      // render: text => <div style={{ textAlign: "left" }}>{text}</div>,
      align: "center",
    },
    {
      title: !!langJson && langJson[`Nebula.phone.tablehd.title2`],
      dataIndex: "modelNumber",
      key: "modelNumber",
      align: "center",
    },
    {
      title: !!langJson && langJson[`Nebula.phone.tablehd.title3`],
      dataIndex: "airCasting",
      key: "airCasting",
      align: "center",
      width: bigScreen ? "" : 70,
      render: text => renderFn(text),
    },
    {
      title: !!langJson && langJson[`Nebula.phone.tablehd.title4`],
      key: "mrspace",
      dataIndex: "mrspace",
      align: "center",
      width: bigScreen ? "" : 70,
      render: text => renderFn(text),
    },
    {
      title: !!langJson && langJson[`Nebula.phone.tablehd.title5`],
      key: "devices",
      dataIndex: "devices",
      align: "center",
      width: bigScreen ? "" : 74,
      render: text => renderFnDevi(text),
    },
  ]
  return (
    <>
      <TabWrapper>
        {tabs.map((k, i) => (
          <Tab key={k} onClick={() => setCate(i)} hl={i === currentCate}>
            <span>{k}</span>
          </Tab>
        ))}
      </TabWrapper>
      {tbdata.length > 0 ? (
        <TabTableWrapper
          tbhead={columns}
          tbdata={tbdata}
          currentCate={currentCate}
        />
      ) : (
        ""
      )}
    </>
  )
}
const SpecsContainer = styled.div`
  position: relative;
  width: calc(100% - 40px);
  max-width: 1000px;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0);
  color: black;
  font-size: 14px;
  letter-spacing: 0.05em;
  @media screen and (max-width: 480px) {
		letter-spacing: normal;
	}
}
`
const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: #000;
  text-align: center;
  margin: 0 auto;
`
const Index = () => {
  const [lang, setLang] = useState('en')
  const [langJson, setLangJson] = useState()
  const langList = {
    'de': "en",
    'en': 'en',
    'es': "en",
    'fr': 'en',
    'it': "en",
    'ja': "ja",
    'ko': "ko",
    'zh': "en"
  }
  useEffect(() => {
    let link = window.location.href.split('?lang=')[1]
    let langListKeyArr = Object.keys(langList)
    if (!!link && langListKeyArr.includes(link)) {
      setLang(langList[link])
      getLanAjax(langList[link])
    } else {
      setLang('en')
      getLanAjax('en')
    }
  }, [])
  const getLanAjax = (lang) => {
    fetch(
      `${process.env.resourceUrl}locales/${lang}.json`
    ).then(response => response.json()).then(res => {
      setLangJson(res)
    })
  }
  return (
    <div >
      <SpecsContainer>
        <S.Spacer h={100} />
        <Title>{langJson && langJson[`compatibilityPage.text1`]}</Title>
        <S.Spacer h={100} />
        <CompatibilityTable langJson={langJson} />
        <S.Spacer h={50} />
        <div className="txTips">
          <span
            dangerouslySetInnerHTML={{
              __html: `${langJson && langJson[`compatibilityPage.text2`]}`,
            }}
          />
        </div>
        <S.Spacer h={50} />
        <div className="txTips">
          <span
            dangerouslySetInnerHTML={{
              __html: `${langJson && langJson[`compatibilityPage.text3`]}`,
            }}
          /><br />
          <span
            dangerouslySetInnerHTML={{
              __html: `${langJson && langJson[`compatibilityPage.text4`]}`,
            }}
          /><br />
          <span
            dangerouslySetInnerHTML={{
              __html: `${langJson && langJson[`compatibilityPage.text5`]}`,
            }}
          /><br />
          <span
            dangerouslySetInnerHTML={{
              __html: `${langJson && langJson[`compatibilityPage.text6`]}`,
            }}
          /><br />
          <span
            dangerouslySetInnerHTML={{
              __html: `${langJson && langJson[`compatibilityPage.text7`]}`,
            }}
          />
        </div>
        <S.Spacer h={250} />
      </SpecsContainer>
    </div>
  )
}

export default Index
